var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Detail Studi Kasus ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"nisn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Siswa","label-for":"nisn","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"nisn","reduce":function (nisn) { return nisn.nisn; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listSiswa,"label":"name","disabled":""},model:{value:(_vm.dataStudent.nisn),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nisn", $$v)},expression:"dataStudent.nisn"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal","type":"date","disabled":""},model:{value:(_vm.dataStudent.date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "date", $$v)},expression:"dataStudent.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Permasalahan","label-for":"problem"}},[_c('validation-provider',{attrs:{"name":"problem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"problem","state":errors.length > 0 ? false : null,"placeholder":"Tanggal","type":"text","disabled":""},model:{value:(_vm.dataStudent.problem),callback:function ($$v) {_vm.$set(_vm.dataStudent, "problem", $$v)},expression:"dataStudent.problem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"background","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Latar Belakang","label-for":"background","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"background","reduce":function (background) { return background.real_id; },"placeholder":"Pilih Siswa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listBackground,"label":"background_desc","disabled":""},model:{value:(_vm.dataStudent.background),callback:function ($$v) {_vm.$set(_vm.dataStudent, "background", $$v)},expression:"dataStudent.background"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Estimasi Permasalahan","label-for":"estimated_problem"}},[_c('validation-provider',{attrs:{"name":"estimated_problem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"estimated_problem","state":errors.length > 0 ? false : null,"placeholder":"Estimasi Permasalahan","type":"text","disabled":""},model:{value:(_vm.dataStudent.estimated_problem),callback:function ($$v) {_vm.$set(_vm.dataStudent, "estimated_problem", $$v)},expression:"dataStudent.estimated_problem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Penanganan","label-for":"handling"}},[_c('validation-provider',{attrs:{"name":"handling","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"handling","state":errors.length > 0 ? false : null,"placeholder":"Penanganan","type":"text","disabled":""},model:{value:(_vm.dataStudent.handling),callback:function ($$v) {_vm.$set(_vm.dataStudent, "handling", $$v)},expression:"dataStudent.handling"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tindak Lanjut","label-for":"follow_up"}},[_c('validation-provider',{attrs:{"name":"follow_up","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"follow_up","state":errors.length > 0 ? false : null,"placeholder":"Tindak Lanjut","type":"text","disabled":""},model:{value:(_vm.dataStudent.follow_up),callback:function ($$v) {_vm.$set(_vm.dataStudent, "follow_up", $$v)},expression:"dataStudent.follow_up"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"bk_teacher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Guru BK","label-for":"bk_teacher","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"bk_teacher","reduce":function (bk_teacher) { return bk_teacher.bk_teacher; },"placeholder":"Pilih Guru","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ListBK,"label":"name","disabled":""},model:{value:(_vm.dataStudent.bk_teacher),callback:function ($$v) {_vm.$set(_vm.dataStudent, "bk_teacher", $$v)},expression:"dataStudent.bk_teacher"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"info"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }